import React from "react"
import AirtimeAndInternet from "../../../../components/body/pages/en-ng/business/bill-payments/airtimeAndInternet"
import Layout from "../../../../components/layout"
import SEO from "../../../../components/seo"

const AirtimeAndInternetPage = () => (
  <Layout>
    <SEO
      canonical={"https://kuda.com/en-ng/business/bill-payments/airtime-internet-data"}
      title="Airtime & Internet Data for Business | Kuda for Business"
      description="Simplify your business communication and stay connected with Kuda's seamless airtime & data purchasing. Explore Kuda for business today!"
    />
    <AirtimeAndInternet />
  </Layout>
)

export default AirtimeAndInternetPage
